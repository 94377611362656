<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    items: string[];
    initial?: string;
    label?: string;
    id?: string;
  }>(),
  {
    items: () => [""],
    initial: "",
    label: "",
    id: "",
  },
);

const selectedItem = ref<string>(props.initial ? props.initial : "");

const emit = defineEmits(["select"]);

const onClick = () => {
  selectedItem.value
    = (selectedItem.value === props.items[0] ? props.items[1] : props.items[0]) ?? "";
  emit("select", selectedItem.value);
};
</script>

<template>
  <div
    :id="id"
    class="switch relative inline-block rounded-sm"
    @click="onClick"
  >
    <div
      class="h-full items-center px-2 py-1"
      :class="label ? 'justify-between' : 'flex justify-center'"
    >
      <label
        v-if="label"
        class="block text-xs font-semibold"
        :for="label"
      >{{
        label
      }}</label>
      <NuxtIcon
        name="fairr:sort"
        class="-ms-2 me-1"
      />
      <span class="dropdown__selected-item">{{ selectedItem }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.switch {
  height: 47px;
  min-width: 160px;
  user-select: none;
  cursor: pointer;

  @apply border border-ui-grey2;

  &__selected-item {
    margin-top: 1px;
  }
}
</style>
